import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Link, Loader, useModal, withModalContext
} from '@jutro/components';
import { withIntl, useTranslator } from '@jutro/locale';
import { withAuthenticationContext, useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { PolicyService } from 'gw-capability-gateway';
import formatDocumentTableData from '../../util/DocumentUtil';
import metadata from './DocumentsComponent.metadata.json5';
import messages from './DocumentsComponent.messages';
import styles from './DocumentsComponent.module.scss';

const getDateModified = (items, index, { path: property }) => {
    return <span>{items[property]}</span>;
};

const DocumentsComponent = (props) => {
    const translator = useTranslator();
    const {
        showConfirm
    } = useModal();

    const {
        authHeader,
        initialDocumentsData,
        showDocumentUpload,
        uploadDocument,
        deleteDocument,
        downloadDocument,
        noDataMessage,
        showPagination,
        showHeader,
        showSearch,
        isLoading,
        value,
        intl
    } = props;
    const userDisplayName = _.get(props, 'authUserData.displayName');
    const { UserService } = useDependencies('UserService');
    const [showDocumentUploadButton, updateShowDocumentUploadButton] = useState(false);
    const [searchKeyword, setKeyword] = useState('');
    const auth = useAuthentication();
    const {
        showAlert
    } = useModal();
    useEffect(() => {
        if (showHeader) {
            const permissionDTO = {
                permission: 'doccreate'
            };
            UserService.hasUserSystemPermission(permissionDTO, authHeader)
                .then((isUserPermission) => {
                    updateShowDocumentUploadButton(isUserPermission);
                });
        }
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDownloadLink = useCallback(
        (item) => {
            const { publicID, sessionID } = item;
            return downloadDocument(publicID, sessionID);
        },
        [downloadDocument]
    );

    const setUploadVisibility = (documentDetails) => {
        return !!documentDetails?.canUploadDocument;
    };
    const onDeleteDocumentIcon = useCallback(
        (e, item) => {
            e.preventDefault();
            const { publicID } = item;
            showConfirm({
                title: messages.removeDocument,
                message: messages.confirmRemoveDocument,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.documentRemoveConfirmYes,
                cancelButtonText: messages.documentRemoveConfirmNo
            }).then(async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                deleteDocument(publicID);
                return true;
            }, _.noop);
        },
        [deleteDocument, showConfirm]
    );

    const uploadDoc = useCallback(
        () => {
            showConfirm({
                title: messages.removeDocument,
                message: messages.confirmRemoveDocument,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.documentRemoveConfirmYes,
                cancelButtonText: messages.documentRemoveConfirmNo
            }).then(async (results) => {
                if (results === 'cancel' || results === 'close') {
                    uploadDocument.click();
                    return _.noop();
                }
                return true;
            }, _.noop);
        },
        [deleteDocument, showConfirm]
    );

    const getRemoveIcon = useCallback(
        (item, index, property) => {
            return (
                <Link
                    href={item[property.id]}
                    onClick={(e) => onDeleteDocumentIcon(e, item)}
                    className={styles.trashLink}
                    icon="mi-delete"
                    aria-label="Delete"
                    disabled={item.author !== auth.user_name}
                />
            );
        },
        [onDeleteDocumentIcon]
    );

    const getNameLink = useCallback(
        (item, index, { path: property }) => {
            return (
                <Link
                    icon="mi-insert_drive_file"
                    onClick={(event) => downloadFile(item, event)}
                    target="_blank"
                    className={styles.documentFileNameColumnLink}
                >
                    {item[property]}
                </Link>
            );
        },
        [getDownloadLink]
    );

    const downloadFile = useCallback(async (item, event) => {
        const isUserClick = event && event.type == 'click' && event.clientX && event.clientY;
        if (!isUserClick) {
            event?.preventDefault();
            return;
        }
        const documentContent = await PolicyService.getViewDocument(item.publicID, authHeader);
        if (documentContent?.error) {
            showAlert({
                title: 'Document Error',
                message: translator(documentContent.error),
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: messages.ok
            }).catch(_.noop);
        } else {
            let fileType = '';
            if (documentContent.extension === 'tif') {
                fileType = 'image/tiff';
            } else if (documentContent.extension === 'pdf') {
                fileType = 'application/pdf';
            }
            const byChar = atob(documentContent.byteArray);
            const bytNum = new Array(byChar.length);
            for (let i = 0; i < byChar.length; i++) {
                bytNum[i] = byChar.charCodeAt(i);
            }
            const byArray = new Uint8Array(bytNum);
            const blob = new Blob([byArray], { type: fileType });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = item.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    }, []);

    const handleSearchValueChange = useCallback((keyword) => {
        setKeyword(keyword);
    }, []);

    const overrideProps = {
        documentTableGrid: {
            data: formatDocumentTableData(intl, initialDocumentsData || value, searchKeyword),
            visible: !_.isEmpty(initialDocumentsData || value) && !isLoading,
            showPagination,
            showSearch
        },
        tilesPageHeaderSpan: {
            visible: (showDocumentUploadButton || showDocumentUpload),
            title: `${translator(messages.uploadToolTip)}`
        },
        noDocumentsDetails: {
            visible: !!noDataMessage && _.isEmpty(initialDocumentsData || value) && !isLoading
        },
        noDocumentsText: {
            message: noDataMessage
        },
        documentsTitleId: {
            visible: showHeader
        },
        uploadDocuments: {
            visible: !showHeader && showDocumentUpload,
        },
        uploadDocumentsHeader: {
            visible: setUploadVisibility(initialDocumentsData?.[0]),
            tooltip: messages.uploadToolTip
        },
        searchFilter: {
            visible: !_.isEmpty(initialDocumentsData || value),
            value: searchKeyword
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getNameLink: getNameLink,
            getDateModified: getDateModified,
            getRemoveIcon: getRemoveIcon,
            uploadDoc: uploadDoc,
            onUploadDocument: uploadDocument,
            handleSearchValueChange: handleSearchValueChange
        }
    };

    return (
        <Loader loaded={!isLoading}>
            <ViewModelForm
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </Loader>
    );
};

DocumentsComponent.propTypes = {
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    initialDocumentsData: PropTypes.shape({}),
    value: PropTypes.shape({}),
    uploadDocument: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    downloadDocument: PropTypes.func.isRequired,
    noDataMessage: PropTypes.string,
    showPagination: PropTypes.bool,
    showHeader: PropTypes.bool,
    showSearch: PropTypes.bool,
    showDocumentUpload: PropTypes.bool,
    isLoading: PropTypes.bool,
    intl: PropTypes.func.isRequired
};

DocumentsComponent.defaultProps = {
    showPagination: true,
    noDataMessage: '',
    showHeader: true,
    showSearch: false,
    showDocumentUpload: false,
    isLoading: false,
    value: undefined,
    initialDocumentsData: undefined
};

export default withIntl(withAuthenticationContext(withModalContext(DocumentsComponent)));
